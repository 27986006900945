import React from "react";
import ClientPanel from "../components/ClientPanel/ClientPanel";

const ClientPanelContainer = () => {
    return (
        <>
            <ClientPanel />
        </>
    );
};

export default ClientPanelContainer;
