export const ADD_IF_ITEM_EMPTY = "ADD_IF_ITEM_EMPTY";
export const ADD_IF_ITEM_EXIST = "ADD_IF_ITEM_EXIST";
export const REMOVE_CART = "REMOVE_CART";
export const ADD_CHECK = "ADD_CHECK";
export const CLEAR_BASKET = "CLEAR_BASKET";
export const ORDER_INPUT_STATE = "ORDER_INPUT_STATE";
export const ORDER_SELECT_INPUT_VALUE = "ORDER_SELECT_INPUT_VALUE";
export const CHANGE_BASKET_AMOUNTS = "CHANGE_BASKET_AMOUNTS";
export const CHANGE_CATEGORY = "CHANGE_CATEGORY";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const FETCH_PRODUCTS_FAILED = "FETCH_PRODUCTS_FAILED";
export const ADD_ORDER_DATA = "ADD_ORDER_DATA";
export const SET_LOCK = "SET_LOCK";
export const NEXT_PAGE = "NEXT_PAGE";
export const PREV_PAGE = "PREV_PAGE";
export const SET_PAGE = "SET_PAGE";
export const ADD_BASKET_ID = "ADD_BASKET_ID";
export const ADD_CLIENT_DATA = "ADD_CLIENT_DATA";
export const DELETE_ITEM = "DELETE_ITEM";
export const COMPANY_ID = "COMPANY_ID";
export const IS_UE = "IS_UE";
export const SET_SEARCH_FIELD = "SET_SEARCH_FIELD";
export const TOKEN = "TOKEN";
export const SET_CLIENT_ORDER_HISTORY = "SET_CLIENT_ORDER_HISTORY";
export const SET_CLIENT_BUDGET_HISTORY = "SET_CLIENT_BUDGET_HISTORY";
export const PRODUCTS_TO_ORDER = "PRODUCTS_TO_ORDER";
export const SET_PRODUCT_CATEGORY = "SET_PRODUCT_CATEGORY";
export const SET_BUDGET = "SET_BUDGET";
export const SET_ORDER_ERROR_TRUE = "SET_ORDER_ERROR_TRUE";
export const SET_ORDER_ERROR_FALSE = "SET_ORDER_ERROR_FALSE";
export const SET_ORDER_NUMBER = "SET_ORDER_NUMBER";
export const ORDER_HISTORY_ID = "ORDER_HISTORY_ID";
export const SET_SINGLE_ORDER_HISTORY = "SET_SINGLE_ORDER_HISTORY";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const COMPANY_NAME = "COMPANY_NAME";
export const USER_NAME = "USER_NAME";
export const SET_CURRENCY_CODE = "SET_CURRENCY_CODE";
export const CANCEL_ORDER_STATUS = "CANCEL_ORDER_STATUS";
export const SET_ORDER_STATUS = "CLEAR_ORDER_STATUS";
export const GET_MARKETING_ORDER_TYPE = "GET_MARKETING_ORDER_TYPE";
export const SUBSCRIBE_RES = "SUBSCRIBE_RES";
export const RESET_SUBSCRIBE = "RESET_SUBSCRIBE";
export const PAID_ORDER_APPLICATION_WORKFLOW_CONF_ID =
    "PAID_ORDER_APPLICATION_WORKFLOW_CONF_ID";
export const RESET_ORDER_ERROR = "RESET_ORDER_ERROR";
export const GET_REMAINING_BUDGET = "GET_REMAINING_BUDGET";
export const SET_REMAINING_BUDGET = "SET_REMAINING_BUDGET";
export const GET_BASE_BUDGET = "GET_BASE_BUDGET";
export const GET_PERIOD_FROM = "GET_PERIOD_FROM";
export const SET_STORE_POLICY_ACCEPTED_STATUS =
    "SET_STORE_POLICY_ACCEPTED_STATUS";
export const SET_STORE_POLICY_TO_ACCEPTED = "SET_STORE_POLICY_TO_ACCEPTED";
export const USER_ID_NUMBER = "USER_ID_NUMBER";
export const SET_PRODUCT_NAME = "SET_PRODUCT_NAME";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const SET_TYPED_PRODUCTS = "SET_TYPED_PRODUCTS";
export const SET_PRODUCTS_CATEGORIES = "SET_PRODUCTS_CATEGORIES";
export const CLEAR_STATE = "CLEAR_STATE";
export const ADD_PRODUCT_CONFIRMATION_PRODUCT_STATE =
    "ADD_PRODUCT_CONFIRMATION_PRODUCT_STATE";
export const CHANGE_DELETE_ALL_PRODUCTS_MODAL_STATE =
    "CHANGE_DELETE_ALL_PRODUCTS_MODAL_STATE";
export const SET_ALIAS_USER_ID = "SET_ALIAS_USER_ID";
export const SET_PAGINATION_TYPE = "SET_PAGINATION_TYPE";
export const SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS";
export const SET_SECOND_DELIVERY_ADDRESS = "SET_SECOND_DELIVERY_ADDRESS";
export const SET_ACTION_GUID = "SET_ACTION_GUID";
