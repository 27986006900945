import React from "react";
import OrderSummary from "../components/Order/OrderSummary";

const OrderContainer = () => {
    return (
        <>
            <OrderSummary />
        </>
    );
};

export default OrderContainer;
