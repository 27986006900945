import * as type from "../actions/types";

import {
    postProduct,
    putProduct,
    removeProduct,
    getBasketProduct,
    changeProduct,
} from "../api";

export const addIfItemEmpty = (id, productQuantity) => {
    return {
        type: type.ADD_IF_ITEM_EMPTY,
        id,
        productQuantity,
    };
};

export const addIfItemExist = (id, productQuantity) => {
    return {
        type: type.ADD_IF_ITEM_EXIST,
        id,
        productQuantity,
    };
};

export const addItemToBasket = (
    id,
    productQuantity,
    unit,
    token,
    quantityLocation,
    alertFunction,
) => {
    let productAmount = Object.values(productQuantity);
    let productNumber = String(productAmount[0]);

    return (dispatch, getState) => {
        let basketId = getState().cartReducer.basket;
        Number(basketId);
        let company = getState().clientDataReducer.companyId;
        let delivery = getState().clientDataReducer.deliveryAddress;
        let companyId =
            company !== "all" ? company.charAt(0).toUpperCase() : "";

        let existing_item = getState().cartReducer.addedItems.find(
            (item) => id === item.product.id,
        );

        if (basketId && !existing_item) {
            putProduct(
                id,
                unit,
                token,
                delivery,
                productNumber,
                companyId,
                basketId,
            )
                .then((res) => {
                    if (res.data.addProduct.error) {
                        dispatch(addProductConfirmationModalState("error"));
                    } else {
                        if (existing_item) {
                            dispatch(addIfItemExist(id, productQuantity));
                        } else {
                            dispatch(addIfItemEmpty(id, productQuantity));
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (basketId && existing_item) {
            const newQuantity = (
                existing_item.quantity + Number(productNumber)
            ).toString();
            changeProduct(
                id,
                newQuantity,
                unit,
                token,
                quantityLocation,
                delivery,
                basketId,
                companyId,
            )
                .then((response) => {
                    if (response.data.updateQuantity.items) {
                        const basketItems = response.data.updateQuantity.items;
                        const addedItem = basketItems.find(
                            (item) => Number(id) === item.id,
                        );
                        dispatch(
                            changeBasketQuantity(
                                id,
                                addedItem.ordered.toString(),
                                quantityLocation,
                            ),
                        );
                        if (Number(newQuantity) > addedItem.ordered) {
                            alertFunction(addedItem.ordered);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        if (!basketId && !existing_item) {
            postProduct(id, unit, token, delivery, productNumber, companyId)
                .then((res) => {
                    if (!res.data.create.order) {
                        dispatch(addProductConfirmationModalState("error"));
                    } else {
                        dispatch(addBasketId(res.data.create.order.id));
                        if (existing_item) {
                            dispatch(addIfItemExist(id, productQuantity));
                        } else {
                            dispatch(addIfItemEmpty(id, productQuantity));
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
};

export const addBasketId = (id) => {
    return { type: type.ADD_BASKET_ID, id };
};

export const removeProd = (token, id) => {
    return (dispatch, getState) => {
        let basketId = getState().cartReducer.basket;

        if (basketId) {
            removeProduct(token, id, basketId)
                .then((res) => {
                    dispatch(deleteItem(id));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
};

export const deleteItem = (id) => {
    return {
        type: type.DELETE_ITEM,
        id,
    };
};

export const addChecked = (item, isChecked) => {
    return {
        type: type.ADD_CHECK,
        item,
        isChecked,
    };
};

export const changeBasketQuantity = (
    productId,
    newProductAmount,
    quantityLocation,
) => {
    return (dispatch, getState) => {
        let changedProductAmount = newProductAmount;
        let value = Number(Object.values(changedProductAmount).join(""));
        let addedItems = getState().cartReducer.addedItems;
        let addedItem = addedItems.find(
            (item) => item.product.id === productId,
        );
        let amount = 0;
        if (quantityLocation) {
            amount = addedItem.quantity;
            amount += value;
            for (let key in changedProductAmount) {
                changedProductAmount[key] = String(amount);
            }
        }
        dispatch(
            changeBasketAmounts(productId, value.toString(), quantityLocation),
        );
    };
};

export const changeBasketAmounts = (
    productId,
    changedProductAmount,
    quantityLocation,
) => {
    return {
        type: type.CHANGE_BASKET_AMOUNTS,
        productId,
        changedProductAmount,
        quantityLocation,
    };
};

export const getBasketProducts = (token) => {
    return (dispatch, getState) => {
        getBasketProduct(token)
            .then((res) => {
                let baskets = [];
                baskets.push(res.data.get);
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const addProductConfirmationModalState = (state) => {
    return {
        type: type.ADD_PRODUCT_CONFIRMATION_PRODUCT_STATE,
        state,
    };
};

export const changeDeleteAllProductsModalState = (modalState) => {
    return {
        type: type.CHANGE_DELETE_ALL_PRODUCTS_MODAL_STATE,
        modalState,
    };
};
