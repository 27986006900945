import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeProd, changeBasketQuantity } from "../../actions/index";
import { changeProduct } from "../../api";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ButtonToolbar } from "react-bootstrap";
import DeleteModal from "./modals/DeleteModal";
import Separator from "./../Separator/Separator";
import { addProductConfirmationModalState } from "../../actions/basket";
import "../../assets/styles/basket.scss";

const BasketButtons = (props) => {
    const [productAmount, setProductAmount] = useState({});
    const [quantityLocation] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const inputValue = useSelector((state) => state.cartReducer.items);
    const totalQuantity = useSelector(
        (state) => state.cartReducer.totalQuantity,
    );
    const basketId = useSelector((state) => state.cartReducer.basket);
    const deliveryAddress = useSelector(
        (state) => state.clientDataReducer.deliveryAddress,
    );
    const company = useSelector((state) => state.clientDataReducer.companyId);
    const companyId = company !== "all" ? company.charAt(0).toUpperCase() : "";

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const input = useRef();

    const token = localStorage.getItem("token");

    useEffect(() => {
        return setProductAmount({
            [props.itemId]: props.itemQuantity,
        });
    }, [props.itemId, props.itemQuantity]);

    const changeAmountHandler = () => {
        const currentQuantity = parseInt(input.current.value, 10);

        inputValue.map((item) => {
            if (input.current.id === item.product.id) {
                if (currentQuantity > parseInt(item.availability, 10)) {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
            }
            //return disabled;
        });
    };

    const confirmationButton = (event) => {
        if (input.current.value <= 0) {
            dispatch(removeProd(token, props.itemId, props.itemUnit));
        } else {
            const productQuantityValue = Object.values(
                input.current.value,
            ).join("");
            const productQuantity = parseInt(productQuantityValue);
            input.current.value = productQuantity === 0 ? 1 : productQuantity;
            changeProduct(
                props.itemId,
                productQuantity === 0 ? 1 : productQuantity,
                props.itemUnit,
                token,
                quantityLocation,
                deliveryAddress,
                basketId,
                companyId,
            )
                .then((response) => {
                    if (response.data.updateQuantity.items) {
                        const basketItems = response.data.updateQuantity.items;
                        const addedItem = basketItems.find(
                            (item) => Number(props.itemId) === item.id,
                        );
                        dispatch(
                            changeBasketQuantity(
                                props.itemId,
                                addedItem.ordered.toString(),
                                quantityLocation,
                            ),
                        );
                        if (
                            Number(
                                Object.values(input.current.value).join(""),
                            ) > addedItem.ordered
                        ) {
                            input.current.value = addedItem.ordered;
                            alert(`${t(`Basket.Alert`)}${addedItem.ordered}`);
                        }
                    }
                })
                .catch((error) => {
                    event.preventDefault();
                });
        }
    };

    const handleShowModal = () => {
        setModalShow(true);
    };

    return (
        <>
            {totalQuantity <= "0" ? <Redirect to="/" /> : ""}
            <div className="item-desc">
                <div className="d-flex justify-content-between">
                    <Link to={`/product/${props.itemId}`}>
                        <h4 className="title text-uppercase">
                            {props.itemTitle}
                        </h4>
                    </Link>
                    <div className="text-right">
                        <ButtonToolbar>
                            <FontAwesomeIcon
                                icon={faTrash}
                                color="#a0a3a6"
                                id={props.itemId}
                                onClick={handleShowModal}
                                cursor="pointer"
                                className="icon-anim f-17"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Usuń"
                            />
                            <DeleteModal
                                show={modalShow}
                                itemID={props.itemId}
                                itemunit={props.itemUnit}
                                itemname={props.itemTitle}
                                onHide={() => setModalShow(false)}
                            />
                        </ButtonToolbar>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-between flex-wrap"
                    style={{ minHeight: "60px" }}
                >
                    <p style={{ margin: "0 5px 0 0" }}>
                        <span>
                            {t(`Card.Price`)}: {Separator(props.itemPrice)}{" "}
                            {props.itemCurrency}
                        </span>
                    </p>
                    <div className="add-remove d-flex align-items-center">
                        <span className="mr-3">
                            <span>
                                {t(`Basket.Qauntity`)}:{" "}
                                <input
                                    type="number"
                                    id={props.itemId}
                                    defaultValue={props.itemQuantity}
                                    ref={input}
                                    className="basket-quantity-input primary-no-action"
                                    onChange={changeAmountHandler}
                                    min="1"
                                />
                            </span>
                        </span>

                        <span
                            className="basket-button btn btn-outline-primary primary-no-action border-button"
                            onClick={confirmationButton}
                        >
                            {t(`Basket.Update`)}
                        </span>
                    </div>
                    <div>
                        <span
                            className="basket-single-item-total"
                            style={{ margin: "0" }}
                        >
                            {t(`Basket.Sum`)}: {Separator(props.itemTotalPrice)}{" "}
                            {props.itemCurrency}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BasketButtons;
