import React from "react";
import OrderEnd from "../components/OrderEnd/OrderEnd";

const OrderEndContainer = () => {
    return (
        <div>
            <OrderEnd />
        </div>
    );
};

export default OrderEndContainer;
